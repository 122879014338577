import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import XLSXStyle from 'xlsx-style-medalsoft';

const OMS = {};

// 导出Excel-单表格Excel  带样式
OMS.downLoadXlsx = ({ dom = 'gx_table', name = '文件', ColumnWdth = [], defaultWidth = 100 }) => {
    const table = document.getElementById(dom);
    // 因为element-ui的表格的fixed属性导致多出一个table，会下载重复内容，这里删除掉
    if (table.querySelector('.el-table__fixed-right')) { table.removeChild(table.querySelector('.el-table__fixed-right')); }
    if (table.querySelector('.el-table__fixed')) { table.removeChild(table.querySelector('.el-table__fixed')); }

    const et = XLSX.utils.table_to_book(table, { raw: true }); // 此处传入table的DOM节点,raw为true表示单元格为文本格式(未加工)

    const wbs = et.Sheets.Sheet1;

    // 设置表格宽度
    for (let i = 0; i < 30; i++) {
        wbs['!cols'][i] = { wpx: defaultWidth };
    }
    ColumnWdth.forEach((item, i) => {
        wbs['!cols'][i] = { wpx: item };
    });

    // 循环遍历每一个表格，设置样式
    for (const key in wbs) {
        if (key.indexOf('!') === -1) {
            // eslint-disable-next-line eqeqeq
            if (key == 'A1' || key == 'B1' || key == 'C1' || key == 'D1' || key == 'E1' || key == 'F1' || key == 'G1' || key == 'H1' || key == 'I1') {
                // console.log('key', key)
                wbs[key].s = {
                    font: {
                        sz: 13, // 字体大小
                        bold: true, // 加粗
                        name: '宋体', // 字体
                        color: {
                            rgb: '000000', // 十六进制，不带#
                        },
                    },
                    alignment: {
                        // 文字居中
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: true, // 文本自动换行
                    },
                    border: {
                        // 设置边框
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    },
                };
            } else {
                wbs[key].s = {
                    font: {
                        sz: 11, // 字体大小
                        bold: false, // 加粗
                        name: '宋体', // 字体
                        color: {
                            rgb: '000000', // 十六进制，不带#
                        },
                    },
                    alignment: {
                        // 文字居中
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: true, // 文本自动换行
                    },
                    border: {
                        // 设置边框
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    },
                };
            }
        }
    }
    const arr = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ];
    const range = wbs['!merges'];
    if (range) {
        range.forEach(item => {
            const startColNumber = Number(item.s.r);
            const endColNumber = Number(item.e.r);
            const startRowNumber = Number(item.s.c);
            const endRowNumber = Number(item.e.c);
            const test = wbs[arr[startRowNumber] + (startColNumber + 1)];
            for (let col = startColNumber; col <= endColNumber; col++) {
                for (let row = startRowNumber; row <= endRowNumber; row++) {
                    wbs[arr[row] + (col + 1)] = test;
                }
            }
        });
    }

    const etout = XLSXStyle.write(et, {
        bookType: 'xlsx',
        type: 'buffer',
    });

    try {
        FileSaver.saveAs(
            new Blob([etout], { type: 'application/octet-stream' }), `${name}.xlsx`); // 导出的文件名
        // eslint-disable-next-line no-empty
    } catch (e) {
    }
    return etout;
};

export default OMS;
