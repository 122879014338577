<template>
    <div class="reportTable">
        <div class="query-wrapper" v-show="showQuery">
            <div class="query-items">
                <div class="query-item" v-if="!customDateMethod">
                    <label class="name">{{ dateLabel }}：</label>
                    <el-date-picker
                        v-model="timeDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        @change="changeDate"
                        :clearable="false"
                    >
                    </el-date-picker>
                </div>
                <slot name="queryItems"></slot>
                <div class="query-item">
                    <el-button type="primary" size="small" @click="search">
                        查询
                    </el-button>
                </div>
            </div>
            <div class="query-btn">
                <slot name="queryBtn"></slot>
                <el-button
                    v-if="toolbar.includes('nowDay')"
                    type="primary"
                    size="small"
                    @click="nowDay"
                >
                    本日
                </el-button>
                <el-button
                    v-if="toolbar.includes('prevDay')"
                    type="primary"
                    size="small"
                    @click="prevDay"
                >
                    上一日
                </el-button>
                <el-button
                    v-if="toolbar.includes('prevWeek')"
                    type="primary"
                    size="small"
                    @click="prevWeek"
                >
                    上周
                </el-button>
                <el-button
                    v-if="toolbar.includes('nowWeek')"
                    type="primary"
                    size="small"
                    @click="nowWeek"
                >
                    本周
                </el-button>
                <el-button
                    v-if="toolbar.includes('prevMonth')"
                    type="primary"
                    size="small"
                    @click="prevMonth "
                >
                    上月
                </el-button>
                <el-button
                    v-if="toolbar.includes('nowMonth')"
                    type="primary"
                    size="small"
                    @click="nowMonth"
                >
                    本月
                </el-button>
                <el-button
                    v-if="toolbar.includes('exportTable')"
                    type="primary"
                    size="small"
                    @click="exportTable"
                >
                    导出
                </el-button>
                <!--                <el-button type="primary" size="small" @click="printTable">-->
                <!--                    打印-->
                <!--                </el-button>-->
            </div>
        </div>
        <div class="report-content">
            <div class="hide-query" :class="{hide: !showQuery}" @click="showQuery=!showQuery">
                <i class="iconfont iconshangjiantou"></i>
            </div>
            <el-progress :percentage="percentage" :stroke-width="4"></el-progress>
            <div class="table-box table-bg" id="download-excel">
                <slot name="customTable" class="table-bg"></slot>
            </div>
            <div class="empty" v-if="noQuery">
                <img src="../../assets/images/default/list_nothing.png">
                <span class="tips">请查询数据</span>
            </div>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="showProgress"
            width="5rem"
            center
            :modal-append-to-body="false"
            :append-to-body="false"
            :close-on-click-modal="false"
            @close="progressClose"
        >
            <div class="progress-box">
                <span class="progress-title" v-if="!errMsg">数据准备中，请稍等...</span>
                <span class="progress-title" style="color: #f66" v-else>{{ errMsg }}</span>
                <el-progress :percentage="generatePercentage" :show-text="false" :stroke-width="8"></el-progress>
                <span class="progress-val">{{ generatePercentage }}%</span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import utils from '@/utils/util';
import OMS from '@/assets/js/exportExcel/exportTools';
export default {
    name: 'report-table',
    props: {
        code: {
            type: String,
            default: '',
        },
        query: {
            type: Object,
            default: () => {},
        },
        tableTitle: {
            type: String,
            default: '导出',
        },
        dateLabel: {
            type: String,
            default: '时间',
        },
        // 如果是字符串 defaultDate 是方法名
        // 如果是数字 defaultDate 是最近天数
        // 如果是数组 是传过来的时间范围
        defaultDate: {
            type: [String, Array, Number],
            default: 'prevDay',
        },
        customDateMethod: {
            type: Function,
            default: null,
        },
        // 进度条
        percentage: {
            type: Number,
            default: 100,
        },
        toolbar: {
            type: Array,
            default: () => {
                return ['nowDay', 'prevDay', 'prevWeek', 'nowWeek', 'prevMonth', 'nowMonth', 'exportTable'];
            },
        },
        customExport: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            noQuery: true,
            // eslint-disable-next-line max-len
            baseUrl: 'http://concrete.cucc.com.cn:8075/WebReport/ReportServer?op=write&__showtoolbar__=false&',
            timeDate: [],
            params: {
                startTime: '',
                endTime: '',
                tenantcode: '',
            },
            tenantId: '',
            showProgress: false,
            errMsg: '',
            generatePercentage: 0,
            // 显示查询区域
            showQuery: true,
            // 终止查询
            abortQuery: false,
        };
    },
    watch: {
        showQuery(val) {
            this.$emit('showQuery', val);
        },
    },
    created() {
        this.params.tenantcode = this.$takeTokenParameters('TenantCode');
        this.tenantId = this.$takeTokenParameters('TenantId');
        this.init();
    },
    mounted() {
        const tableHead = document.querySelector('.table-head');
        const tableBody = document.querySelector('.table-body');
        tableBody.addEventListener('scroll', () => {
            // 滚动距离
            tableHead.scrollLeft = Math.floor(tableBody.scrollLeft);
        });
    },
    methods: {
        init() {
            if (this.customDateMethod) {
                this.customDateMethod(this.params);
                this.search();
            } else if (typeof this.defaultDate === 'string') { // 如果是字符串 defaultDate 是方法名
                this[this.defaultDate]();
            } else if (typeof this.defaultDate === 'number') { // 如果是数字 defaultDate 是最近天数
                this.recentDays(this.defaultDate);
            } else { // 自定是时间 数组 范围
                this.params.startTime = this.defaultDate[0];
                this.params.endTime = this.defaultDate[1];
                this.search();
            }

        },
        search() {
            this.generateResult();
        },
        // 生成结果数据
        async generateResult() {
            this.errMsg = '';
            this.generatePercentage = 0;
            this.showProgress = true;
            this.abortQuery = false;
            try {
                const res = await this.generateResultApi();
                if ( this.abortQuery ) {
                    return;
                }
                if (!res) {
                    this.generatePercentage = 100;
                    this.generateComplete();
                } else {
                    this.getGenerateProgress();
                }
            } catch (e) {
                this.errMsg = e.ErrorCode.Message;
                clearInterval(this.timer);
            }
        },
        generateResultApi() {
            if (this.customDateMethod) {
                this.customDateMethod(this.params);
            }
            // eslint-disable-next-line max-len
            const url = `/interfaceApi/report/ReportResultData/generate/${this.tenantId}/${this.code}?startTime=${this.params.startTime}&endTime=${this.params.endTime}`;
            return this.$axios.get(url);
        },
        generateComplete() {
            clearInterval(this.timer);
            this.$nextTick(() => {
                this.noQuery = false;
                this.showProgress = false;
                const params = Object.assign({}, this.params, this.query);
                this.$emit('generateComplete', params);
            });
        },
        // 获取数据生成进度
        async getGenerateProgress() {
            const updateProgress = async () => {
                try {
                    const url = `/interfaceApi/report/ReportResultData/generate_progress/${this.tenantId}/${this.code}`;
                    const res = await this.$axios.get(url);
                    if (res.code === '-1') {
                        // 生成数据报错
                        this.errMsg = res.message;
                        clearInterval(this.timer);
                    } else if (res.code === '0') {
                        // 正在生成中
                        if (res.progress >= this.generatePercentage) {
                            this.generatePercentage = res.progress;
                        }
                    } else if (res.code === '1') {
                        // 生成成功
                        this.generatePercentage = 100;
                        this.generateComplete();
                    }
                } catch (e) {
                    this.errMsg = e.ErrorCode.Message;
                    clearInterval(this.timer);
                }

            };
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                updateProgress();
            }, 1000);
        },
        progressClose() {
            this.abortQuery = true;
            clearInterval(this.timer);
        },
        // 变更日期
        changeDate(val) {
            if (!val) {
                const nowDate = utils.timeFormat(new Date(), 'yyyy-MM-dd');
                this.timeDate = [nowDate, nowDate];
                this.params.startTime = nowDate;
                this.params.endTime = nowDate;
            } else {
                this.params.startTime = val[0];
                this.params.endTime = val[1];
            }
        },
        // 导出
        exportTable() {
            if (this.customExport) {
                this.$emit('exportTable');
            } else {
                OMS.downLoadXlsx({
                    dom: 'download-excel',
                    name: this.tableTitle,
                });
            }
        },
        // 打印
        printTable() {
            // eslint-disable-next-line no-undef
            // window.open(this.reportUrl + '&format=pdfPrint');
            // document.getElementById('reportTableIframe').contentWindow.contentPane.pdfPrint();
        },
        // 本日
        nowDay() {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            const s = utils.timeFormat(start, 'yyyy-MM-dd');
            const e = utils.timeFormat(end, 'yyyy-MM-dd');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 上一日
        prevDay() {
            const start = new Date();
            const end = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24);
            const s = utils.timeFormat(start, 'yyyy-MM-dd');
            const e = utils.timeFormat(end, 'yyyy-MM-dd');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 上周
        prevWeek() {
            const start = new Date();
            const end = new Date();
            start.setDate(start.getDate() - start.getDay() - 6);
            end.setDate(end.getDate() - end.getDay() + 1);
            const s = utils.timeFormat(start, 'yyyy-MM-dd');
            const e = utils.timeFormat(end, 'yyyy-MM-dd');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 本周
        nowWeek() {
            const start = new Date();
            const end = new Date();
            start.setDate(start.getDate() - start.getDay() + 1);
            end.setDate(end.getDate() - end.getDay() + 8);
            const s = utils.timeFormat(start, 'yyyy-MM-dd');
            const e = utils.timeFormat(end, 'yyyy-MM-dd');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 上一月
        prevMonth() {
            const start = new Date();
            const end = new Date();
            start.setMonth(start.getMonth() - 1);
            const s = utils.timeFormat(start, 'yyyy-MM-01');
            const e = utils.timeFormat(end, 'yyyy-MM-01');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 本月
        nowMonth() {
            const start = new Date();
            const end = new Date();
            end.setMonth(start.getMonth() + 1);
            const s = utils.timeFormat(start, 'yyyy-MM-01');
            const e = utils.timeFormat(end, 'yyyy-MM-01');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 最近一月
        recentMonth() {
            const start = new Date();
            const end = new Date();
            start.setMonth(start.getMonth() - 1);
            end.setDate(end.getDate() + 1);
            const s = utils.timeFormat(start, 'yyyy-MM-dd');
            const e = utils.timeFormat(end, 'yyyy-MM-dd');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 最近多少天
        recentDays(days = 0) {
            const start = new Date();
            const end = new Date();
            start.setDate(start.getDate() - days + 1);
            end.setDate(end.getDate() + 1);
            const s = utils.timeFormat(start, 'yyyy-MM-dd');
            const e = utils.timeFormat(end, 'yyyy-MM-dd');
            this.timeDate = [s, e];
            this.params.startTime = s;
            this.params.endTime = e;
            this.search();
        },
        // 地址处理中文
        cjkEncode(text) {
            if (text === null) {
                return '';
            }
            let newText = '';
            for (let i = 0; i < text.length; i++) {
                const code = text.charCodeAt(i);
                if (code >= 128 || code === 91 || code === 93) { // 91 is "[", 93 is "]".
                    newText += '[' + code.toString(16) + ']';
                } else {
                    newText += text.charAt(i);
                }
            }
            return newText;
        },

    },
    destroyed() {
        clearInterval(this.timer);
    },
};
</script>

<style lang="stylus">
.reportTable
    padding 0.2rem
    display flex
    flex-direction column
    height 100%
    position relative
    background #fff
    .query-wrapper
        display flex
        flex-wrap wrap
        background #fff!important
        .query-items
            width 100%
            display flex
            flex-wrap wrap
            .query-item
                display flex
                align-items center
                margin-right 0.15rem
                margin-bottom 0.1rem
                background #F7F8FB
                .name
                    width 1.2rem
                    white-space nowrap
                    flex-shrink 0
                    display inline-block
                    text-align right
                    font-size 0.18rem
                .el-date-editor
                    display flex
                    align-items center
                .el-date-editor.el-input__inner,.el-select
                    width 2.6rem
                    border 0
                    background transparent
                    padding-left 0
                .el-range-input
                    font-size 0.14rem
                    background transparent
                .el-input__icon,.el-range-separator
                    line-height 0.3rem
                    font-size 0.14rem
                .el-range__close-icon
                    display none
                .el-input
                    width 2.6rem
                    .el-input__inner
                        height 0.44rem
                        border 0
                        font-size 0.16rem
                        background transparent
                    .el-input__suffix
                        display flex
                        align-items center
                .el-button
                    width 0.9rem!important
                    height 0.4rem!important
        .el-button
            min-width 0.8rem
            height 0.32rem
            font-size 0.16rem
            display flex
            justify-content center
            align-items center
            background url(../../assets/images/default/btn.png)
            background-size 100% 100%
            border 0
            border-radius 0
        .query-btn
            width 100%
            display flex
            .el-button
                background url(../../assets/images/default/btn1.png)
                background-size 100% 100%
    .report-content
        padding-bottom 0.4rem
        width 100%
        flex 1
        position relative
        overflow hidden
        .hide-query
            margin 0 auto
            width: 1rem;
            height: 0.12rem;
            background: #F7F8FB;
            border-radius: 0.04rem 0.04rem 0 0;
            display flex
            justify-content center
            align-items center
            cursor pointer
            position relative
            z-index 9
            .iconfont
                color #979EA7
                font-size 0.08rem
                transform scale(0.8)
            &.hide
                .iconfont
                    transform rotate(180deg) scale(0.8)
        iframe
            width 100%
            height 100%
            border 0

        .table-box
            display flex
            flex-direction column
    .progress-box
        display: flex;
        flex-direction: column;
        text-align center
        .progress-title
            font-size 0.18rem
            margin-bottom 0.4rem
        .progress-val
            margin-top 0.1rem
            font-size 0.16rem
    .el-dialog
        display: flex;
        display: -ms-flex; /* 兼容IE */
        flex-direction: column;
        -ms-flex-direction: column; /* 兼容IE */
        margin:0 !important;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    .el-dialog__wrapper,.v-modal
        position absolute
    .empty
        width 100%
        height 100%
        display flex
        flex-direction column
        justify-content center
        align-items center
        img
            width 11rem
            height 6.4rem
        .tips
            margin-left 0.4rem
            margin-top -2rem
            font-size 0.24rem
            color #999
    .el-table
        min-width 100%
        border 1px solid rgb(215,215,215)!important
        width max-content
        table
            border 0
        tr
            th,td
                padding 0.06rem 0.03rem;
                text-align center
                font-size 0.14rem
                font-family: SimSun;
                border-right 1px solid rgb(215,215,215)
                border-bottom 1px solid rgb(215,215,215)
                word-break:break-all;
                word-wrap:break-word;
            th
                text-align center
                color rgb(2,39,130)
                background rgb(237,240,245)
        .table-head
            width 100%
            overflow-x hidden
            overflow-y scroll
            table
                border-right 0
                border-bottom 0
        .el-table__empty-block
            height 100%
    >>> .el-progress
        margin-top -0.06rem
    table
        min-width 100%
        border 1px solid rgb(215,215,215)
        width max-content
        tr
            th,td
                padding 0.06rem 0.03rem;
                text-align center
                font-size 0.14rem
                font-family: SimSun;
                border-right 1px solid rgb(215,215,215)
                border-bottom 1px solid rgb(215,215,215)
                word-break:break-all;
                word-wrap:break-word;
            th
                color rgb(2,39,130)
                background rgb(237,240,245)
    .table-head
        width 100%
        overflow-x hidden
        overflow-y scroll
        table
            border-right 0
            border-bottom 0
    .table-body
        width 100%
        flex 1
        overflow-y scroll
        border 1px solid #d7d7d7
        border-top 0
        table
            border-right 0
            border-top 0
</style>
